import { signedInUser } from '@ctm/auth/store';
import { browser, version } from '$app/environment';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

export function setupNewRelic() {
	if (!browser) {
		return;
	}

	const applicationID = environmentSettings.newRelicApplicationID;
	if (!applicationID) {
		console.warn('[NewRelic] no applicationID in environment settings');
		return;
	}

	const options = {
		init: {
			session_replay: {
				enabled: true,
				block_selector: '',
				mask_text_selector: '*',
				sampling_rate: 10.0,
				error_sampling_rate: 100.0,
				mask_all_inputs: true,
				collect_fonts: true,
				inline_images: false,
				inline_stylesheet: true,
				mask_input_options: {},
			},
			distributed_tracing: { enabled: true },
			privacy: { cookies_enabled: true },
			ajax: { deny_list: ['bam.nr-data.net'] },
		},
		info: { beacon: 'bam.nr-data.net', errorBeacon: 'bam.nr-data.net', licenseKey: 'NRJS-548daa526257d90f2c0', applicationID, sa: 1 },
		loader_config: { accountID: '4475278', trustKey: '4475278', agentID: applicationID, licenseKey: 'NRJS-548daa526257d90f2c0', applicationID },
	};

	const newrelicBrowserAgent = new BrowserAgent(options);

	newrelicBrowserAgent.setApplicationVersion(version);

	signedInUser.subscribe((user) => {
		if (user) {
			newrelicBrowserAgent.setUserId(user.id);
		} else {
			newrelicBrowserAgent.setUserId(null);
		}
	});
}
