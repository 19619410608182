import { setupNewRelic } from '$lib/helpers/newRelic';
import { setupAppInsights, track } from '$lib/helpers/appInsights.js';
import { setupPendo } from '$lib/helpers/pendo.js';

setupNewRelic();
setupAppInsights();
if (environmentSettings.pendoApiKey) {
	setupPendo(environmentSettings.pendoApiKey);
}

export function handleError({ error, event, status, message }) {
	track.exception(error, { event, status, message });
	return {
		message,
		error,
	};
}
